import React, { useState, useRef, useEffect } from 'react';
import './styles/MenuMobile.css';
import logo from '../assets/Logo_Navemar.svg';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import menuIcon from '../assets/icons/Icono-menu-homepage.svg';
import menuLogin from '../assets/icons/Icono-usuario-home page.svg';
import customArrowIcon from '../assets/icons/Icono-flecha-menu.svg';
import { Trans, useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import pdfFile from '../components/formatos/AM-FT-10 Autorización Funcionarios para Tramites V6.xlsx';
import pdfFilePoder from '../components/formatos/NYK _NAVEMAR 2025 PODER LIBERACION BL.doc';
import LoginModal from './pages/LoginModal';
import { useAuth } from '../AuthContext';
import { setCookie, getCookie } from '../cookies';
import config from '../configuration/config';

const MenuMobile = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [language, setLanguage] = useState('es');
  const openLogin = () => setIsLoginOpen(true);
  const closeLogin = () => setIsLoginOpen(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState({
    services: false,
    transaccion: false,
    pagos: false,
    itinerarios: false,
    formatos: false,
    poderes: false,
    saes: false,
    fletes: false,
    cargos: false,
    formatosNyk: false,
    poderesCargue: false,
    poderesConsulta: false,
    saesCargue: false,
    saesConsulta: false,
  });

  const [isMenuIdioma, setIsMenuIdioma] = useState(false);
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const { logout } = useAuth();
  const handleLogout = () => {
    logout();
  };

  const urlCargue = config.carguePoderes;
  const urlConsulta = config.consultaPoderes;

  const navigate = useNavigate();
  const openPerfil = () => {
    navigate('/perfil');
  };


  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSubMenuClick = (key) => {
    setIsSubMenuOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleMenuIdioma = () => {
    setIsMenuIdioma(!isMenuIdioma);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    setCookie('cookieLanguage', lng, { expires: 30 });
    localStorage.setItem('cookieLanguage', lng);
};

useEffect(() => {
  const cookieLanguage = getCookie('cookieLanguage');
  if (cookieLanguage && cookieLanguage !== language) {
      i18n.changeLanguage(cookieLanguage);
      setLanguage(cookieLanguage);
  }
}, [i18n, language]);

  const menuRef = useRef();
  const iconoMenuRef = useRef();

  // Efecto para agregar o remover el listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Si el menú está abierto y el clic fue fuera del menú, lo cierra
      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        event.target !== iconoMenuRef.current
      ) {
        setIsMenuOpen(false);
      }
    };

    // Agrega el listener cuando el menú está abierto
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Limpieza: Remueve el listener cuando el componente se desmonta o el menú se cierra
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const servicios = [
    { titulo: <Trans>menu.servicio_agenciamiento</Trans>, linkto: '/agenciamiento-maritimo-comercial-y-portuario', orden: 1 },
    { titulo: <Trans>menu.servicio_aerolinea</Trans>, linkto: '/representacion-de-aerolineas', orden: 2 },
    { titulo: <Trans>menu.servicio_tecnologia</Trans>, linkto: '/soluciones-de-tecnologia', orden: 3 },
    { titulo: <Trans>menu.servicio_bpo</Trans>, linkto: '/gestion-de-liberacion-facturacion-y-recaudo-de-gastos-locales', orden: 4 },
  ];

  const serviciosOrdenados = servicios.sort((a, b) => a.orden - b.orden);

  const renderMenu = () => {
    return (
      <ul>
        {serviciosOrdenados.map((item, index) => (
          <li key={index}>
            <NavLink to={item.linkto} className="full-width-navlink-sub">{item.titulo}</NavLink>
          </li>
        ))}
      </ul>
    );
  };

  const handleDownload = () => {
    saveAs(pdfFile, 'AM-FT-10 Autorización Funcionarios para Tramites V6.xlsx');
};

const handleDownloadPoder = () => {
    saveAs(pdfFilePoder, 'NYK _NAVEMAR 2025 PODER LIBERACION BL.doc');
};

  const submitFormPoder = (url, tipo) => {

    const form = document.getElementById('f_cargue_menu');
    form.action = url;
    form.target = '_blank';
    if (tipo === "1") {
      const input = document.getElementById('LINEAHAPPA');
      const input2 = document.getElementById('LINEAEVER');
      const input3 = document.getElementById('LINEANYK');
      input.value = "LINEAHAPA";
      input2.value = "";
      input3.value = "";
    } else if (tipo === "2") {
      const input = document.getElementById('LINEAEVER');
      const input2 = document.getElementById('LINEAHAPPA');
      const input3 = document.getElementById('LINEANYK');
      input.value = input.name;
      input2.value = "";
      input3.value = "";
    } else if (tipo === "3") {
      const input = document.getElementById('LINEANYK');
      const input2 = document.getElementById('LINEAEVER');
      const input3 = document.getElementById('LINEAHAPPA');
      input.value = "LINEANAVE";
      input2.value = "";
      input3.value = "";
    } else if (tipo === "4") {
      const input = document.getElementById('LINEANYK');
      const input2 = document.getElementById('LINEAEVER');
      const input3 = document.getElementById('LINEAHAPPA');
      input.value = "";
      input2.value = "";
      input3.value = "";
    }

    form.submit();
  };

  const openPagosMenu = () => {
    const form = document.getElementById('f_pagos_menu');
    if (form) {
      form.target = '_blank';
      const nit = document.getElementById('PTWEB_NIT');
      const nitsistema = document.getElementById('PTWEB_NITSISTEMA');
      if (nit && nitsistema) {
        nit.value = user.nit;
        nitsistema.value = user.nitsistema;
        form.submit();
      }
    }
  };

  const handlePagosMenu = (event) => {
    event.preventDefault();
    if (user) {
      openPagosMenu();
    } else {
      openLogin();
    }
  };

  const handleItemClickCargue = (tipo) => {
    submitFormPoder(urlCargue, tipo);
  };
  const handleItemClickConsulta = (tipo) => {
    submitFormPoder(urlConsulta, tipo);
  };

  return (
    <nav className="mobile-navbar">
      <div className="navbar-content">
        <div className="">
          <Link to={user ? "/inicio" : "/"}>
            <img src={logo} alt="Logo" className='logo_navemar' />
          </Link>
        </div>
        <div className="menu-icon" >
          <img src={menuLogin} alt="Menu Icon" className="icono-login" onClick={user ? openPerfil : openLogin} />
          <img src={menuIcon} alt="Menu Icon" className="icono-menu" ref={iconoMenuRef} onClick={handleMenuClick} />
        </div>
      </div>
      <div className={`menu-items ${isMenuOpen ? 'open' : ''}`} ref={menuRef} style={{ width: isMenuOpen ? '50%' : '0', margin: isMenuOpen ? '0 auto' : '0' }}>
        <ul>
          <li>
            <NavLink to={user ? "/inicio" : "/"} className="full-width-navlink"><Trans>menu.home</Trans></NavLink>
          </li>
          <li>
            <NavLink to="/quienes-somos" className="full-width-navlink"><Trans>menu.about</Trans></NavLink>
          </li>
          <li>
            <NavLink to="/nuestros-servicios" className="full-width-navlink" onClick={() => handleSubMenuClick('services')}>
              <span className='menu-link'>
                <Trans>menu.services</Trans>
                <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
              </span>
            </NavLink>
            <span className={`menu-link-1 ${isSubMenuOpen.services ? 'open' : ''}`}>
              {renderMenu()}
            </span>
          </li>
          <li>
            <NavLink to="/tramites-y-consultas" className="full-width-navlink" onClick={() => handleSubMenuClick('transaccion')}>
              <span className='menu-link'>
                <Trans>menu.transaccion</Trans>
                <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
              </span>
            </NavLink>
            <span className={`menu-link-1 ${isSubMenuOpen.transaccion ? 'open' : ''}`}>
              <ul>
                <li>
                  <Link to="#" onClick={() => handleSubMenuClick('pagos')} className="full-width-navlink-sub">
                    <span className='menu-link'>
                      <Trans>menu.transaccion_pagos</Trans>
                      <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                    </span>
                  </Link>
                  <span className={`submenu-link ${isSubMenuOpen.pagos ? 'open' : ''}`}>
                    <ul>
                      <li><a className="full-width-navlink-sub" href="https://navesoftonline.com/serviciosenlinea.html" rel="noreferrer noopener" target="_blank"><Trans>submenu.pagos_hapag</Trans></a></li>
                      <li><a className="full-width-navlink-sub" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER" rel="noreferrer noopener" target="_blank"><Trans>submenu.pagos_evergreen</Trans></a></li>
                      <li><Link className="full-width-navlink-sub" to="#" onClick={handlePagosMenu}><Trans>submenu.pagos_nyk</Trans></Link></li>
                    </ul>
                  </span>
                </li>
                <li>
                  <Link to="#" onClick={() => handleSubMenuClick('itinerarios')} className="full-width-navlink-sub">
                    <span className='menu-link'>
                      <Trans>menu.transaccion_itinerarios</Trans>
                      <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                    </span>
                  </Link>
                  <span className={`submenu-link ${isSubMenuOpen.itinerarios ? 'open' : ''}`}>
                    <ul>
                      <li><a className="full-width-navlink-sub" href="https://www.hapag-lloyd.com/es/online-business/schedule/schedule-download-solution.html" rel="noreferrer noopener" target="_blank"><Trans>submenu.itinerarios_hapag</Trans></a></li>
                      <li><a className="full-width-navlink-sub" href="https://www.shipmentlink.com/co/" rel="noreferrer noopener" target="_blank"><Trans>submenu.itinerarios_ever</Trans></a></li>
                      {/*<li><a className="full-width-navlink-sub" href="https://www.nykroro.com/customer/schedules/" rel="noreferrer noopener" target="_blank"><Trans>submenu.itinerarios_nyk</Trans></a></li>*/}
                      <li><NavLink className="full-width-navlink-sub" to="/itinerario-nyk" exact="true"><Trans>submenu.itinerarios_nyk</Trans></NavLink></li>
                    </ul>
                  </span>
                </li>
                <li>
                  <Link to="#" onClick={() => handleSubMenuClick('formatos')} className="full-width-navlink-sub">
                    <span className='menu-link'>
                      <Trans>menu.transaccion_formatos</Trans>
                      <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                    </span>
                  </Link>
                  <span className={`submenu-link ${isSubMenuOpen.formatos ? 'open' : ''}`}>
                    <ul>
                      <li><a className="full-width-navlink-sub" href="https://navesoftonline.com/serviciosenlinea.html" rel="noreferrer noopener" target="_blank"><Trans>submenu.formato_hapag</Trans></a></li>
                      <li><a className="full-width-navlink-sub" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Descargue Formatos" rel="noreferrer noopener" target="_blank"><Trans>submenu.formato_evergreen</Trans></a></li>
                      <li>
                        <Link to="#" onClick={() => handleSubMenuClick('formatosNyk', true)} className="full-width-navlink-sub">
                          <span className='menu-link'>
                            <Trans>submenu.formato_nyk</Trans>
                            <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                          </span>
                        </Link>
                        <span className={`submenu-link-2 ${isSubMenuOpen.formatosNyk ? 'open' : ''}`}>
                          <ul>
                            <li><Link to="" className="full-width-navlink-sub" onClick={handleDownload}><Trans>submenu.formato_nyk_autorizacion</Trans></Link></li>
                            <li><Link to="" className="full-width-navlink-sub" onClick={handleDownloadPoder}><Trans>submenu.formato_nyk_poder</Trans></Link></li>
                          </ul>
                        </span>
                      </li>
                    </ul>
                  </span>
                </li>
                <li>
                  <Link to="#" onClick={() => handleSubMenuClick('poderes', true)} className="full-width-navlink-sub">
                    <span className='menu-link'>
                      <Trans>menu.transaccion_poderes</Trans>
                      <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                    </span>
                  </Link>
                  <span className={`submenu-link ${isSubMenuOpen.poderes ? 'open' : ''}`}>
                    <ul>
                      <li>
                        <Link to="#" onClick={() => handleSubMenuClick('poderesCargue', true)} className="full-width-navlink-sub">
                          <span className='menu-link'>
                            <Trans>submenu.poderes_cargue</Trans>
                            <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                          </span>
                        </Link>
                        <span className={`submenu-link-2 ${isSubMenuOpen.poderesCargue ? 'open' : ''}`}>
                          <ul>
                            <li><Link to="" className="full-width-navlink-sub" onClick={() => handleItemClickCargue("1")}><Trans>submenu.poderes_cargue_hapag</Trans></Link></li>
                            <li><Link to="" className="full-width-navlink-sub" onClick={() => handleItemClickCargue("2")}><Trans>submenu.poderes_cargue_evergreen</Trans></Link></li>
                            <li><Link to="" className="full-width-navlink-sub" onClick={() => handleItemClickCargue("3")}><Trans>submenu.poderes_cargue_nyk</Trans></Link></li>
                            <li><Link to="" className="full-width-navlink-sub" onClick={() => handleItemClickCargue("4")}><Trans>submenu.poderes_cargue_admin</Trans></Link></li>
                          </ul>
                        </span>
                      </li>
                      <li>
                        <Link to="#" onClick={() => handleSubMenuClick('poderesConsulta', true)} className="full-width-navlink-sub">
                          <span className='menu-link'>
                            <Trans>submenu.poderes_consulta</Trans>
                            <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                          </span>
                        </Link>
                        <span className={`submenu-link-2 ${isSubMenuOpen.poderesConsulta ? 'open' : ''}`}>
                          <ul>
                            <li><Link to="" className="full-width-navlink-sub" onClick={() => handleItemClickConsulta("1")}><Trans>submenu.poderes_consulta_hapag</Trans></Link></li>
                            <li><Link to="" className="full-width-navlink-sub" onClick={() => handleItemClickConsulta("2")}><Trans>submenu.poderes_consulta_evergreen</Trans></Link></li>
                            <li><Link to="" className="full-width-navlink-sub" onClick={() => handleItemClickConsulta("3")}><Trans>submenu.poderes_consulta_nyk</Trans></Link></li>
                          </ul>
                        </span>
                      </li>
                    </ul>
                  </span>
                </li>
                <li>
                  <Link to="/tramites-y-consultas#buques" className="full-width-navlink-sub">
                    <span className='menu-link'><Trans>menu.transaccion_buques</Trans></span>
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={() => handleSubMenuClick('saes', true)} className="full-width-navlink-sub">
                    <span className='menu-link'>
                      <Trans>menu.transaccion_saes</Trans>
                      <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                    </span>
                  </Link>
                  <span className={`submenu-link ${isSubMenuOpen.saes ? 'open' : ''}`}>
                    <ul>
                      <li>
                        <Link to="#" onClick={() => handleSubMenuClick('saesCargue', true)} className="full-width-navlink-sub">
                          <span className='menu-link'>
                            <Trans>submenu.saes_cargue</Trans>
                            <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                          </span>
                        </Link>
                        <span className={`submenu-link-2 ${isSubMenuOpen.saesCargue ? 'open' : ''}`}>
                          <ul>
                            <li><a className="full-width-navlink-sub" href="https://formsweb.navesoft.com/ords_halocol_croma/web_carga_archivos" rel="noreferrer noopener" target="_blank"><Trans>submenu.saes_cargue_hapag</Trans></a></li>
                            <li><a className="full-width-navlink-sub" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Documentar mi embarque" rel="noreferrer noopener" target="_blank"><Trans>submenu.saes_cargue_evergreen</Trans></a></li>
                          </ul>
                        </span>
                      </li>
                    </ul>
                  </span>
                </li>
                <li>
                  <Link to="#" onClick={() => handleSubMenuClick('fletes')} className="full-width-navlink-sub">
                    <span className='menu-link'>
                      <Trans>menu.transaccion_fletes</Trans>
                      <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                    </span>
                  </Link>
                  <span className={`submenu-link ${isSubMenuOpen.fletes ? 'open' : ''}`}>
                    <ul>
                      <li><Link to="/contactenos?origen=FLETES" className="full-width-navlink-sub"><Trans>submenu.fletes_nyk</Trans></Link></li>
                    </ul>
                  </span>
                </li>
                <li>
                  <Link to="#" onClick={() => handleSubMenuClick('cargos')} className="full-width-navlink-sub">
                    <span className='menu-link'>
                      <Trans>menu.transaccion_cargos</Trans>
                      <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                    </span>
                  </Link>
                  <span className={`submenu-link ${isSubMenuOpen.cargos ? 'open' : ''}`}>
                    <ul>
                      <li><a className="full-width-navlink-sub" href="https://www.hapag-lloyd.com/es/online-business/quotation/tariffs/local-charges-service-fees.html#anchor_306979" rel="noreferrer noopener" target="_blank"><Trans>submenu.cargos_hapag</Trans></a></li>
                      <li><a className="full-width-navlink-sub" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Tarifas" rel="noreferrer noopener" target="_blank"><Trans>submenu.cargos_evergreen</Trans></a></li>
                    </ul>
                  </span>
                </li>

              {/* consulta liberacion */}

                <li>
                  <Link to="#" onClick={() => handleSubMenuClick('cargos')} className="full-width-navlink-sub">
                    <span className='menu-link'>
                      <Trans>menu.consulta_liberacion</Trans>
                      <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                    </span>
                  </Link>
                  <span className={`submenu-link ${isSubMenuOpen.cargos ? 'open' : ''}`}>
                    <ul>
                      <li><a className="full-width-navlink-sub" href="https://navesoftonline.com/serviciosenlinea.html" rel="noreferrer noopener" target="_blank"><Trans>submenu.consulta_hapag</Trans></a></li>
                      <li><a className="full-width-navlink-sub" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Consulta liberación" rel="noreferrer noopener" target="_blank"><Trans>submenu.consulta_evergreen</Trans></a></li>
                      <li><a className="full-width-navlink-sub" href="https://formsweb.navesoft.com/ords_navemar_croma/WEB_LIBERACION" rel="noreferrer noopener" target="_blank"><Trans>submenu.consulta_nyk</Trans></a></li>

                    </ul>
                  </span>
                </li>

                {/* Seguimiento embarque */}

                <li>
                  <Link to="#" onClick={() => handleSubMenuClick('cargos')} className="full-width-navlink-sub">
                    <span className='menu-link'>
                      <Trans>menu.seguimiento_embarque</Trans>
                      <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                    </span>
                  </Link>
                  <span className={`submenu-link ${isSubMenuOpen.cargos ? 'open' : ''}`}>
                    <ul>
                      <li><a className="full-width-navlink-sub" href="https://www.hapag-lloyd.com/en/online-business/track/track-by-container-solution.html" rel="noreferrer noopener" target="_blank"><Trans>submenu.embarque_hapag</Trans></a></li>
                      <li><a className="full-width-navlink-sub" href="https://ct.shipmentlink.com/servlet/TDB1_CargoTracking.do" rel="noreferrer noopener" target="_blank"><Trans>submenu.embarque_evergreen</Trans></a></li>
                      <li><a className="full-width-navlink-sub" href="https://www.nykroro.com/customer/cargo-tracking/" rel="noreferrer noopener" target="_blank"><Trans>submenu.embarque_nyk</Trans></a></li>

                    </ul>
                  </span>
                </li>

                <li><a className="full-width-navlink-sub" href="https://www.jotform.com/es/build/240397114928663" rel="noreferrer noopener" target="_blank"><Trans>submenu.transaccion_sagrilaft</Trans></a></li>
              </ul>
            </span>
          </li>
          <li>
            <Link to="#" onClick={handleMenuIdioma} className="full-width-navlink">
              <span className='menu-link'><Trans>menu.idioma</Trans>
                <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
              </span>
            </Link>
            <ul>
              <li>
                <Link to="#" className="full-width-navlink-sub" onClick={() => changeLanguage('es')}><Trans>menu.spanish</Trans></Link>
              </li>
              <li>
                <Link to="#" className="full-width-navlink-sub" onClick={() => changeLanguage('en')}><Trans>menu.english</Trans></Link>
              </li>
            </ul>
          </li>
          <li>
            {user ?
              <Link to="#" onClick={handleMenuIdioma} className="full-width-navlink">
                <span className='menu-link' onClick={handleLogout}><Trans>menu.logout</Trans></span>
              </Link>
              : ""}
          </li>
        </ul>
      </div >
      <form id="f_cargue_menu" name="f_cargue_menu" action="" target="_blank" method="post" >
        <input type="hidden" name="LINEAHAPPA" id="LINEAHAPPA" />
        <input type="hidden" name="LINEAEVER" id="LINEAEVER" />
        <input type="hidden" name="LINEANYK" id="LINEANYK" />
        <input type="hidden" name="PORIGEN" id="PORIGEN" value="N" />
        <input type="hidden" name="pdesdenavesoft" id="pdesdenavesoft" value="S" />
      </form>
      <form id="f_pagos_menu" name="f_pagos_menu" action="https://formsweb.navesoft.com/ords_navemar_croma/envio_factura" method="post" >
        <input type="hidden" name="PACCION" id="PACCION" value="M" />
        <input type="hidden" name="PTWEB_NIT" id="PTWEB_NIT" />
        <input type="hidden" name="PTWEB_NITSISTEMA" id="PTWEB_NITSISTEMA" />
      </form>
      <LoginModal isOpen={isLoginOpen} onClose={closeLogin} rutaRedirect="/inicio" />
    </nav >
  );
}

export default MenuMobile;